import styled from "styled-components"
import { Link } from "react-router-dom"

export const NavbarContainer = styled.nav`
    width: 100%;
    height: 50%;
    background-color: red;
    display: flex;
    flex-direction: column;
  
`
export const NavLink = styled(Link)`
    display: flex;
    font-size: x-large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration:none;
    color: black;

`
export const Menu = styled.div`
  display: flex;
  align-items: center; 
  justify-content: space-evenly;
  `