import React from "react";
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import home from "./pages/home"
import aboutus from "./pages/aboutus"
import contactus from "./pages/contactus"
import Navbar from "./components/Navbar"
function App() {
  return (
   
    <BrowserRouter>
     <Navbar/>
      <Routes>
        <Route path="/" Component={home}/>
        <Route path="/aboutus" Component={aboutus}/>
        <Route path="/contactus" Component={contactus}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
