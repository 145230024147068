import React from 'react';
import {NavbarContainer, NavLink, Menu} from './NavbarElements'

function index(props) {
    return (
        <NavbarContainer>
            <Menu>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/aboutus">About</NavLink>
            <NavLink to="/contactus">Contact</NavLink>
            </Menu>
        </NavbarContainer>
            
       
    );
}

export default index;